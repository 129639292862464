import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Input,
  FormHelperText,
  Typography,
  FormControlLabel,
  Checkbox,
  IconButton,
  TextField,
  Box
} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Homepage } from 'interfaces/homepage';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector } from 'react-redux';
import { fetchProductsGeneral } from 'redux/ducks/stock';
import IStore from 'interfaces/store';
import { editBanner } from 'redux/ducks/banner';
import Validator from 'validator';
import { Banner } from 'interfaces/banner';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch } from 'react-redux';
import { createHomepage, patchHomepage } from 'redux/ducks/homepage';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'black',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      color: '#fe309c',
      backgroundColor: 'black'
    }
  }
});

interface IProps {
  onClose: () => void;
  open: boolean;
  reload: () => void;
  homepage: Homepage;
  banner: Banner;
}

const theme = (createTheme as any)({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: 'auto',
        maxWidth: '25vw'
      },
      text: {
        fontSize: '1rem'
      },
      icon: {
        width: '33px'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0
      },
      imageContainer: {
        maxWidth: '100%',
        flexBasis: '100%'
      }
    }
  }
});

export default function CreateHomepage(props: IProps) {
  const classes = useStyles()

  const { onClose, reload, homepage, banner } = props;
  const dispatch = useDispatch();
  const [video, setVideo] = useState({
    previewVideo: '',
    youtubeURL: '',
    videoartistName: '',
    videoTitle: '',
    videoYear: '',
    videoArtEdition: '',
    videoPresented: ''
  });
  const [latest, setLatest] = useState({
    latest_image: null,
    latest_title: '',
    latest_hover_text: '',
    latest_url: ''
  });
  const [featured, setFeatured] = useState({
    featured_artist_title: '',
    featured_artist_image: '',
    featured_artist_page_url: ''
  });
  const [services, setServices] = useState({
    services_image_title: '',
    services_title: '',
    services_hover_text: ''
  });
  const [social, setSocial] = useState({
    social_image: null,
    social_title: '',
    social_link: ''
  });
  const [gifting, setGifing] = useState({
    gifting_url: '',
    gifting_first_message: '',
    gifting_second_message: ''
  });
  const [links, setLinks] = useState({
    whatsapp: '',
    wechat: '',
    email: '',
    instagram: '',
    facebook: ''
  });
  const [video2, setVideo2] = useState({
    previewVideo: '',
    youtubeURL: '',
    videoartistName: '',
    videoTitle: '',
    videoYear: '',
    videoArtEdition: '',
    videoPresented: ''
  });

  useEffect(() => {
    setVideo({
      previewVideo: homepage?.vc1_preview_video,
      youtubeURL: homepage?.vc1_preview_video,
      videoartistName: homepage?.vc1_artist_name,
      videoTitle: homepage?.vc1_video_title,
      videoYear: homepage?.vc1_year,
      videoArtEdition: homepage?.vc1_art_edition,
      videoPresented: homepage?.vc1_presented_by
    });
    setLatest({
      latest_image: homepage?.latest_image,
      latest_title: homepage?.latest_title,
      latest_hover_text: homepage?.latest_hover_text,
      latest_url: homepage?.latest_url
    });
    setFeatured({
      featured_artist_title: homepage?.featured_artist_title,
      featured_artist_image: homepage?.featured_artist_image,
      featured_artist_page_url: homepage?.featured_artist_page_url
    });
    setServices({
      services_image_title: homepage?.services_image_title,
      services_title: homepage?.services_title,
      services_hover_text: homepage?.services_hover_text
    });
    setSocial({
      social_image: homepage?.social_image,
      social_title: homepage?.social_title,
      social_link: homepage?.social_link
    });
    setGifing({
      gifting_url: homepage?.gifting_url,
      gifting_first_message: homepage?.gifting_first_message,
      gifting_second_message: homepage?.gifting_second_message
    });
    setLinks({
      whatsapp: homepage?.whatsapp,
      wechat: homepage?.wechat,
      email: homepage?.email,
      instagram: homepage?.instagram,
      facebook: homepage?.facebook
    });
    setVideo2({
      previewVideo: homepage?.vc2_preview_video,
      youtubeURL: homepage?.vc2_preview_video,
      videoartistName: homepage?.vc2_artist_name,
      videoTitle: homepage?.vc2_video_title,
      videoYear: homepage?.vc2_year,
      videoArtEdition: homepage?.vc2_art_edition,
      videoPresented: homepage?.vc2_presented_by
    });
  }, [homepage]);
  const { stock } = useSelector<IStore, IStore>((state) => state);
  const [bannerId, setBannerId] = React.useState(0);
  const [placementTitle, setPlacementTitle] = React.useState('');
  const [placementTitleError, setPlacementError] = React.useState('');
  const [placementDesc, setPlacementDesc] = React.useState('');
  const [placementDescError, setPlacementDescError] = React.useState('');
  const [allEditions, setAllEditions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState(['']);
  const [slidesError, setSlidesError] = React.useState([['', '', '', '', '']]);
  const [imagePaths, setImagePaths] = React.useState([]);
  const [previewImage, setPreviewImage] = React.useState([]);
  const [imagePathsPortrait, setImagePathsPortrait] = React.useState([]);
  const [previewImagePortrait, setPreviewImagePortrait] = React.useState([]);
  const [previewVideo, setPreviewVideo] = React.useState([]);
  const [clicked, setClicked] = React.useState(false);

  const [slides, setSlides] = React.useState([
    {
      id: 0,
      image: '',
      video: '',
      external_url: '',
      description: '',
      hide_description: false,
      image_portrait: '',
      sort_key: 0
    }
  ]);
  const [deletedSlides, setDeletedSlides] = React.useState([
    {
      id: 0,
      image: '',
      video: '',
      external_url: '',
      description: '',
      hide_description: false,
      image_portrait: '',
      sort_key: 0
    }
  ]);
  const [productError, setProductError] = React.useState(['']);
  const [product, setProduct] = React.useState([
    {
      id: 0,
      productName: '',
      value: 0
    }
  ]);

  useEffect(() => {
    try {
      if (allEditions.length === 0) {
        dispatch(fetchProductsGeneral());
      }
      if (stock && stock.products.length > 0) {
        const y = [];
        stock.products.forEach((element) => {
          const x = {
            id: element.product_id,
            productName: element.name,
            value: element.price
          };
          y.push(x);
        });
        setAllEditions(y);
      }
      if (banner && placementTitle === '') {
        setBannerId(banner.id);
        setPlacementDesc(banner.placement_description);
        setPlacementTitle(banner.placement_title);
        if (banner.banner_slides) {
          const x = new Array(banner.banner_slides.length).fill([
            '',
            '',
            '',
            '',
            ''
          ]);
          setSlidesError(x);
          const q = new Array(banner.banner_slides.length).fill('');
          const z = new Array(banner.banner_slides.length).fill(false);
          const qz = new Array(banner.banner_slides.length).fill('');
          const zx = new Array(banner.banner_slides.length).fill('');
          const y = [];
          banner.banner_slides.map((element, index) => {
            y.push({
              id: element?.internal_product?.product_id,
              productName: element?.internal_product?.name,
              value: element?.internal_product?.price
            });
            zx[index] = element?.internal_product?.name;
            q[index] = element.image;
            qz[index] = element.image_portrait;
          });
          setProduct(y);
          setImagePaths(q);
          setPreviewImage(z);
          setImagePathsPortrait(qz);
          setPreviewImagePortrait(z);
          setPreviewVideo(z);
          setInputValue(zx);
        }
        setSlides(banner.banner_slides);
      }
    } catch (e) {
      console.log(e);
    }
  }, [stock.products, banner]);

  const checkFormError = () => {
    let entered = false;
    if (placementTitle === '') {
      setPlacementError('Please enter placement title');
      entered = true;
    }
    if (placementDesc === '') {
      setPlacementDescError('Please enter placement description');
      entered = true;
    }
    slides.map((element, index) => {
      /*
      if (element.description === '') {
        const x = [...slidesError];
        x[index][0] = 'Please enter description';
        setSlidesError(x);
        entered = true;
      }
      if (element.image === '') {
        const x = [...slidesError];
        x[index][1] = 'Please upload image';
        setSlidesError(x);
        entered = true;
      }
      if (element.image_portrait === '') {
        const x = [...slidesError];
        x[index][4] = 'Please upload image';
        setSlidesError(x);
        entered = true;
      }
      */
      if (
        element.external_url !== '' &&
        Validator.isURL(element.external_url) == false
      ) {
        const x = [...slidesError];
        x[index][3] =
          'Please enter a valid external url (http(s)://something.something)';
        setSlidesError(x);
        entered = true;
      }

      if (
        product[index].productName !== null &&
        product[index].productName === '' &&
        element.external_url === ''
      ) {
        const x = [...productError];
        x[index] = 'Please enter a product';
        setProductError(x);
        entered = true;
      }
      if (product[index].productName !== null &&
        product[index].productName !== '' &&
        element.external_url !== '') {
        const x = [...slidesError];
        x[index][3] =
          'You can either enter an external URL or select an artwork';
        setSlidesError(x);
        entered = true;
      }
    });
    return entered;
  };

  const handleSubmitBanner = () => {
    if (!checkFormError()) {
      setClicked(true);
      const banner = {
        placement_title: placementTitle,
        placement_description: placementDesc
      };
      const newBannerSlides = [];
      const oldBannerSlides = [];
      const deletedSlidess = [];
      slides.map((element, index) => {
        if (element.id === 0) {
          const elementSubmitted = {
            image: element.image,
            image_portrait: element.image_portrait,
            video: element.video,
            external_url:
              element.external_url !== null && element.external_url !== '' ? element.external_url : '',
            description: element.description,
            hide_description: element.hide_description,
            internal_product: product[index].id === 0 ? '' : product[index].id,
            sort_key: element.sort_key
          };
          try {
            if (
              elementSubmitted.image.includes('https://res.cloudinary.com/')
            ) {
              delete elementSubmitted.image;
            }
            if (
              elementSubmitted.image_portrait.includes(
                'https://res.cloudinary.com/'
              )
            ) {
              delete elementSubmitted.image_portrait;
            }
          } catch (e) {
            console.log(e);
          }
          if (element.external_url !== null && element.external_url !== '') {
            elementSubmitted.internal_product = "";
          }
          newBannerSlides.push(elementSubmitted);
        } else {
          const elementSubmitted = {
            id: element.id,
            image: element.image,
            image_portrait: element.image_portrait,
            video: element.video,
            external_url:
              element.external_url !== null && element.external_url !== '' ? element.external_url : '',
            description: element.description,
            hide_description: element.hide_description,
            internal_product: product[index].id === 0 ? '' : product[index].id,
            sort_key: element.sort_key
          };

          try {
            if (
              elementSubmitted.image.includes('https://res.cloudinary.com/')
            ) {
              delete elementSubmitted.image;
            }
            if (
              elementSubmitted.image_portrait.includes(
                'https://res.cloudinary.com/'
              )
            ) {
              delete elementSubmitted.image_portrait;
            }
          } catch (e) {
            console.log(e);
          }
          if (element.external_url !== null && element.external_url !== '') {
            elementSubmitted.internal_product = "";
          }
          oldBannerSlides.push(elementSubmitted);
        }
      });
      deletedSlides.map((element) => {
        if (element.id !== 0) {
          deletedSlidess.push({
            id: element.id
          });
        }
      });
      console.log("banners ", newBannerSlides, oldBannerSlides)
      dispatch(
        editBanner(
          bannerId,
          banner,
          newBannerSlides,
          oldBannerSlides,
          deletedSlidess
        )
      ).then((result: number) => {
        if (result === 0) {
          reload();
          handleClose();
        } else {
          setClicked(false);
        }
      });
    }
  };

  const handlePlacementTitle = (value: any) => {
    setPlacementTitle(value);
    setPlacementError('');
  };
  const handlePlacementDesc = (value: any) => {
    setPlacementDesc(value);
    setPlacementDescError('');
  };
  const handleSlideDesc = (value: any, index: number) => {
    const x = [...slides];
    x[index].description = value;
    setSlides(x);
    const z = [...slidesError];
    z[index][0] = '';
    setSlidesError(z);
  };
  const handleSlideExternalURL = (value: any, index: number) => {
    const x = [...slides];
    x[index].external_url = value;
    setSlides(x);
    const z = [...slidesError];
    z[index][3] = '';
    setSlidesError(z);
  };
  const handleHideDescription = (index: number) => {
    const x = [...slides];
    x[index].hide_description = !x[index].hide_description;
    setSlides(x);
  };

  const addSlide = () => {
    const x = [...slides];
    x.push({
      id: 0,
      image: '',
      video: '',
      external_url: '',
      description: '',
      hide_description: false,
      image_portrait: '',
      sort_key: 0
    });
    setSlides(x);
    const y = [...product];
    y.push({
      id: 0,
      productName: '',
      value: 0
    });
    setProduct(y);
    const w = [...productError];
    w.push('');
    setProductError(w);
    const z = [...slidesError];
    z.push(['', '', '', '']);
    setSlidesError(z);
  };

  const deleteSlide = (index: number) => {
    const x = [...slides];
    const z = [...deletedSlides];
    z.push(x[index]);
    setDeletedSlides(z);
    x.splice(index, 1);
    setSlides(x);
    const y = [...product];
    y.splice(index, 1);
    setProduct(y);
  };

  const handleImage = (event: any, index) => {
    if (event.length > 0) {
      const x = [...slides];
      x[index].image = event[0];
      setSlides(x);
      const z = [...slidesError];
      z[index][1] = '';
      setSlidesError(z);
      const w = [...imagePaths];
      const url = URL.createObjectURL(event[0]);
      w[index] = url;
      setImagePaths(w);
      const q = [...previewImage];
      q[index] = true;
      setPreviewImage(q);
    }
  };
  const handleImagePortrait = (event: any, index) => {
    if (event.length > 0) {
      const x = [...slides];
      x[index].image_portrait = event[0];
      setSlides(x);
      const z = [...slidesError];
      z[index][4] = '';
      setSlidesError(z);
      const w = [...imagePathsPortrait];
      const url = URL.createObjectURL(event[0]);
      w[index] = url;
      setImagePathsPortrait(w);
      const q = [...previewImagePortrait];
      q[index] = true;
      setPreviewImagePortrait(q);
    }
  };

  const handleChangeProduct = (value: any, index: number) => {
    if (value !== null) {
      const y = [...productError];
      y[index] = '';
      setProductError(y);
      const x = [...product];
      x[index] = value;
      setProduct(x);
    } else {
      const x = [...product];
      x[index] = {
        id: 0,
        productName: '',
        value: 0
      };
      setProduct(x);
    }
  };

  const handleVideo = (event: any, index) => {
    if (event.length > 0) {
      const x = [...slides];
      x[index].video = event[0];
      setSlides(x);
      const z = [...slidesError];
      z[index][2] = '';
      setSlidesError(z);
      const q = [...previewVideo];
      q[index] = true;
      setPreviewVideo(q);
    }
  };

  const [orderedSlides, setOrderedSlides] = React.useState([]);

  useEffect(() => {
    const tempOrderedSlides = orderedSlides;
    banner?.banner_slides.map((slide) => {
      tempOrderedSlides.push({
        sortKey: slide.sort_key
      });
    });
    setOrderedSlides(tempOrderedSlides);
  }, [banner]);

  const handleOrderedSlides = (inputValue: number, inputIndex: number) => {
    const tempOrderedSlides = [];
    orderedSlides?.map((slide, index) => {
      index === inputIndex
        ? tempOrderedSlides.push({
          sortKey: inputValue
        })
        : tempOrderedSlides.push({
          sortKey: slide.sortKey
        });
    });
    banner.banner_slides[inputIndex].sort_key = inputValue;
    setOrderedSlides(tempOrderedSlides);

    return null;
  };

  const getSlideSortKey = (index: number) => {
    let sortKey = 1;
    if (orderedSlides.length > 0) {
      if (orderedSlides[index]) {
        sortKey = orderedSlides[index].sortKey;
      }
      return sortKey;
    }
    return null;
  };

  const disableOrderSelect = (index: number) => {
    const incrementedIndex = index + 1;
    if (incrementedIndex > banner?.banner_slides.length) {
      return true;
    }
    return false;
  };
  const handleChangeVideo = (prop, value) => {
    setVideo({
      ...video,
      [prop]: value
    });
  };
  const handleChangeLatest = (prop, value) => {
    setLatest({
      ...latest,
      [prop]: value
    });
  };
  const handleFeatured = (prop, value) => {
    setFeatured({
      ...featured,
      [prop]: value
    });
  };
  const handleServices = (prop, value) => {
    setServices({
      ...services,
      [prop]: value
    });
  };
  const handleSocial = (prop, value) => {
    setSocial({
      ...social,
      [prop]: value
    });
  };
  const handleGifting = (prop, value) => {
    setGifing({
      ...gifting,
      [prop]: value
    });
  };
  const handleLinks = (prop, value) => {
    setLinks({
      ...links,
      [prop]: value
    });
  };
  const handleVideo2 = (prop, value) => {
    setVideo2({
      ...video2,
      [prop]: value
    });
  };
  const handleClose = () => {
    setClicked(false);
    onClose();
  };

  const treatURL = (url: string) => {
    if (url.includes('https://res.cloudinary.com/arbid/')) {
      return url;
    }
    return `https://res.cloudinary.com/arbid/${url}`;
  };

  const handleSubmit = () => {
    setClicked(true);
    try {
    handleSubmitBanner();
    } catch (e) {
      console.log(e);
    }
    var data = {
      vc1_preview_video: video.previewVideo,
      vc1_youtube_video_url: video.youtubeURL,
      vc1_artist_name: video.videoartistName,
      vc1_video_title: video.videoTitle,
      vc1_year: video.videoYear,
      vc1_art_edition: video.videoArtEdition,
      vc1_presented_by: video.videoPresented,
      vc2_preview_video: video2.previewVideo,
      vc2_youtube_video_url: video2.youtubeURL,
      vc2_artist_name: video2.videoartistName,
      vc2_video_title: video2.videoTitle,
      vc2_year: video2.videoYear,
      vc2_art_edition: video2.videoArtEdition,
      vc2_presented_by: video2.videoPresented,
      ...latest,
      ...services,
      ...social,
      ...gifting,
      ...video2,
      ...featured,
      ...links
    };
    var patchFiles = {
      vc1_preview_video: '',
      vc2_preview_video: '',
      latest_image: '',
      social_image: '',
      featured_artist_image: ''
    };

    if (typeof video.previewVideo != 'string') {
      patchFiles.vc1_preview_video = video.previewVideo;
      data.vc1_preview_video = '';
    } else {
      delete patchFiles.vc1_preview_video;
    }
    if (typeof video2.previewVideo != 'string') {
      patchFiles.vc2_preview_video = video2.previewVideo;
      data.vc2_preview_video = '';
    } else {
      delete patchFiles.vc2_preview_video;
    }
    if (typeof latest.latest_image != 'string') {
      patchFiles.latest_image = data.latest_image;
      data.latest_image = '';
    } else {
      delete patchFiles.latest_image;
    }
    if (typeof social.social_image != 'string') {
      patchFiles.social_image = data.social_image;
      data.social_image = '';
    } else {
      delete patchFiles.social_image;
    }
    if (typeof featured.featured_artist_image != 'string') {
      patchFiles.featured_artist_image = data.featured_artist_image;
      data.featured_artist_image = '';
    } else {
      delete patchFiles.featured_artist_image;
    }
    if (homepage) {
      dispatch(patchHomepage(data, patchFiles, homepage.id)).then(
        (result: number) => {
          if (result === 1) {
            reload();
            handleClose();
          } else {
            setClicked(false);
          }
        }
      );
    } else {
      dispatch(createHomepage(data)).then((result: number) => {
        if (result === 1) {
          reload();
          handleClose();
        } else {
          setClicked(false);
        }
      });
    }
  };

  return (
    <div>
      <DialogTitle id="simple-dialog-title">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div>Homepage content</div>
          <div>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSubmit}
              disabled={clicked}
              className={classes.activeButton}
            >
              Save
            </Button>
          </div>
        </div>
      </DialogTitle>
      <br />
      <Box style={{ border: '1px solid lightgray', borderRadius: '10px' }}>
        <Grid container spacing={2} style={{ padding: '2vw' }}>
          <div style={{ width: '100%' }}>
            <DialogTitle id="simple-dialog-title">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <div>Edit Banner Container</div>
              </div>
            </DialogTitle>
            <div style={{ padding: '2vw' }}>
              <Grid>
                <FormControl
                  style={{
                    marginTop: '0px',
                    width: '20vw'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                  error={placementTitleError !== ''}
                >
                  <InputLabel htmlFor="name-input">Placement Title</InputLabel>
                  <Input
                    id="name-input"
                    type="text"
                    value={placementTitle}
                    onChange={(e) => {
                      handlePlacementTitle(e.target.value);
                    }}
                  />
                  <FormHelperText>{placementTitleError}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid>
                <FormControl
                  style={{
                    marginTop: '0px',
                    width: '20vw'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                  error={placementDescError !== ''}
                >
                  <InputLabel htmlFor="name-input">
                    Placement Description
                  </InputLabel>
                  <Input
                    id="name-input"
                    type="text"
                    value={placementDesc}
                    onChange={(e) => {
                      handlePlacementDesc(e.target.value);
                    }}
                    multiline
                    rows={3}
                  />
                  <FormHelperText>{placementDescError}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid
                style={{
                  fontSize: '0.9vw',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <div>Slides</div>
                <IconButton onClick={addSlide} style={{ marginLeft: '1vw' }}>
                  <AddIcon></AddIcon>
                </IconButton>
              </Grid>
              {slides.map((element, index) => (
                <Accordion aria-controls="panel1a-content" id="panel1a-header">
                  <AccordionSummary>
                    <Typography>
                      {getSlideSortKey(index)} {element.description}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <Grid
                        style={{
                          border: '1px solid black',
                          borderRadius: '1vw',
                          width: 'fit-content',
                          padding: '1vw',
                          marginTop: '1vw'
                        }}
                      >
                        <Grid
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                          }}
                        >
                          <FormControl
                            style={{
                              marginTop: '0px',
                              width: '20vw'
                            }}
                            // fullWidth={true}
                            margin={'normal'}
                            error={slidesError[index][0] !== ''}
                          >
                            <InputLabel htmlFor="name-input">
                              Slide Description
                            </InputLabel>
                            <Input
                              id="name-input"
                              type="text"
                              value={element.description}
                              onChange={(e) => {
                                handleSlideDesc(e.target.value, index);
                              }}
                              multiline
                              rows={3}
                            />
                            <FormHelperText>
                              {slidesError[index][0]}
                            </FormHelperText>
                          </FormControl>

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'end'
                            }}
                          >
                            <FormControl
                              style={{
                                marginTop: '0px',
                                width: '7vw',
                                paddingTop: '1vw'
                              }}
                              // fullWidth={true}
                              margin={'normal'}
                              error={false}
                            >
                              <InputLabel id="orderSelect-label">
                                Order
                              </InputLabel>
                              <Select
                                labelId="orderSelect-label"
                                id="orderSelect"
                                value={getSlideSortKey(index)}
                                label="Order"
                                onChange={(e) =>
                                  handleOrderedSlides(
                                    Number(e.target.value),
                                    index
                                  )
                                }
                                variant="standard"
                                disabled={disableOrderSelect(index)}
                              >
                                {banner?.banner_slides?.map((p, index) => (
                                  <MenuItem value={index + 1}>
                                    {index + 1}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <Typography
                              variant="caption"
                              style={{ color: 'red' }}
                            >
                              {disableOrderSelect(index)
                                ? 'Please save before change this order'
                                : ''}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid>
                          <FormControlLabel
                            style={{
                              marginLeft: 'unset'
                            }}
                            control={
                              <Checkbox
                                checked={element.hide_description}
                                onChange={(e) => {
                                  handleHideDescription(index);
                                }}
                                name="hideDescriptionCheckbox"
                                color="primary"
                              />
                            }
                            label="Hide Description"
                          />
                        </Grid>
                        <Grid>
                          <FormControl
                            style={{
                              marginTop: '25px',
                              width: '20vw'
                            }}
                            fullWidth={true}
                            margin={'normal'}
                            error={slidesError[index][3] !== ''}
                          >
                            <InputLabel htmlFor="name-input">
                              External URL
                            </InputLabel>
                            <Input
                              id="name-input"
                              type="text"
                              value={element.external_url}
                              onChange={(e) => {
                                handleSlideExternalURL(e.target.value, index);
                              }}
                            />
                            <FormHelperText>
                              {slidesError[index][3]}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid
                          style={{
                            display: 'flex',
                            flexDirection: 'row'
                          }}
                        >
                          <FormControl
                            style={{
                              marginTop: '0px',
                              marginBottom: '2vw',
                              width: '20vw'
                            }}
                            fullWidth={true}
                            margin={'normal'}
                            error={productError[index] !== ''}
                            required
                          >
                            <Autocomplete
                              value={product[index]}
                              onChange={(
                                event: any,
                                newValue: string | null
                              ) => {
                                handleChangeProduct(newValue, index);
                              }}
                              inputValue={inputValue[index]}
                              onInputChange={(event, newInputValue) => {
                                const x = [...inputValue];
                                x[index] = newInputValue;
                                setInputValue(x);
                              }}
                              options={allEditions}
                              getOptionLabel={(option) => {
                                if (
                                  option.productName !== undefined &&
                                  option.id !== 0
                                ) {
                                  return option.productName;
                                } else return '';
                              }}
                              renderOption={(option) =>
                                option.productName !== undefined
                                  ? option.productName
                                  : ''
                              }
                              loading={allEditions.length === 0}
                              loadingText={'Loading products'}
                              renderInput={(params) => (
                                <TextField {...params} label="Artwork" />
                              )}
                            />
                            <FormHelperText>
                              {productError[index]}
                            </FormHelperText>
                          </FormControl>
                          <FormControl
                            style={{
                              marginTop: '0px',
                              marginLeft: '2vw',
                              width: '17.5vw'
                            }}
                            fullWidth
                            margin={'normal'}
                          >
                            <InputLabel htmlFor="name-input">Value</InputLabel>
                            <Input
                              id="name-input"
                              type="text"
                              value={product[index].value}
                              disabled
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '1vw',
                            flexDirection: 'column'
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <DropzoneArea
                              acceptedFiles={['image/*']}
                              dropzoneText={
                                'Drag and drop the Slide image here'
                              }
                              showPreviewsInDropzone={true}
                              onChange={(files) => handleImage(files, index)}
                              filesLimit={0}
                            />
                            <Typography
                              variant="caption"
                              style={{ color: '#fe309c' }}
                            >
                              For better resolution insert images with 2500x1250
                            </Typography>
                            <br />
                          </ThemeProvider>
                          <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <div
                              style={{
                                display: previewImage[index] ? 'none' : 'flex',
                                flexDirection: 'column',
                                maxWidth: '3vw'
                              }}
                            >
                              <div>
                                <img
                                  src={imagePaths[index]}
                                  style={{
                                    width: '3vw',
                                    height: '3vw',
                                    marginTop: '1vw'
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <FormHelperText style={{ color: 'red' }}>
                            {slidesError[index][1]}
                          </FormHelperText>
                        </Grid>
                        <Grid
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '1vw',
                            flexDirection: 'column'
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <DropzoneArea
                              acceptedFiles={['image/*']}
                              dropzoneText={
                                'Drag and drop the Slide image portrait here'
                              }
                              showPreviewsInDropzone={true}
                              onChange={(files) =>
                                handleImagePortrait(files, index)
                              }
                              filesLimit={0}
                            />
                            <Typography
                              variant="caption"
                              style={{ color: '#fe309c' }}
                            >
                              For better resolution insert images with 1080x1920
                            </Typography>
                            <br />
                          </ThemeProvider>
                          <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <div
                              style={{
                                display: previewImagePortrait[index]
                                  ? 'none'
                                  : 'flex',
                                flexDirection: 'column',
                                maxWidth: '3vw'
                              }}
                            >
                              <div>
                                <img
                                  src={imagePathsPortrait[index]}
                                  style={{
                                    width: '3vw',
                                    height: '3vw',
                                    marginTop: '1vw'
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <FormHelperText style={{ color: 'red' }}>
                            {slidesError[index][5]}
                          </FormHelperText>
                        </Grid>
                        <Grid
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '1vw',
                            flexDirection: 'column'
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <DropzoneArea
                              acceptedFiles={['video/*']}
                              dropzoneText={
                                'Drag and drop the Slide video here'
                              }
                              showPreviewsInDropzone={true}
                              onChange={(files) => handleVideo(files, index)}
                              filesLimit={0}
                            />
                          </ThemeProvider>
                          <div
                            style={{
                              display:
                                slides[index].video !== '' ? 'flex' : 'none',
                              flexDirection: 'row'
                            }}
                          >
                            <div
                              style={{
                                display: previewVideo[index] ? 'none' : 'flex',
                                flexDirection: 'column',
                                maxWidth: '3vw'
                              }}
                            >
                              <div>
                                <IconButton>
                                  <VideoCallIcon
                                    onClick={() =>
                                      window.open(slides[index].video, '_blank')
                                    }
                                  ></VideoCallIcon>
                                </IconButton>
                              </div>
                            </div>
                          </div>
                          <FormHelperText style={{ color: 'red' }}>
                            {slidesError[index][2]}
                          </FormHelperText>
                        </Grid>
                      </Grid>
                      <Grid>
                        <IconButton
                          disabled={index === 0}
                          onClick={() => {
                            deleteSlide(index);
                          }}
                        >
                          <DeleteIcon></DeleteIcon>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </Grid>
      </Box>
      <br />
      <Box style={{ border: '1px solid lightgray', borderRadius: '10px' }}>
        <Grid container spacing={2} style={{ padding: '2vw' }}>
          <Typography component="h5">Video content</Typography>
          <Grid
            lg={12}
            md={12}
            sm={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1vw',
              flexDirection: 'row'
            }}
          >
            <Grid lg={6} md={6} sm={12}>
              <ThemeProvider theme={theme}>
                <DropzoneArea
                  acceptedFiles={['video/*']}
                  dropzoneText={'Drag and drop the preview video here'}
                  showPreviewsInDropzone={true}
                  onChange={(event) => {
                    if (event.length > 0) {
                      handleChangeVideo('previewVideo', event[0]);
                    }
                  }}
                  filesLimit={1}
                  maxFileSize={30000000}
                />
                <Typography variant="caption" style={{ color: '#fe309c' }}>
                  For better resolution insert videos with 1920x1080
                </Typography>
                <br />
              </ThemeProvider>
            </Grid>
            <FormHelperText style={{ color: 'red' }}></FormHelperText>
            <Grid lg={6} md={6} sm={12}>
              {typeof video.previewVideo === 'string' && (
                <video
                  style={{ width: '70%', height: '70%' }}
                  controls
                  src={treatURL(video.previewVideo)}
                />
              )}
            </Grid>
          </Grid>
          <br />
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Youtube video URL</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={video.youtubeURL}
                onChange={(e) => {
                  handleChangeVideo('youtubeURL', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Video artist name</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={video.videoartistName}
                onChange={(e) => {
                  handleChangeVideo('videoartistName', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Video Title</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={video.videoTitle}
                onChange={(e) => {
                  handleChangeVideo('videoTitle', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Video year</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={video.videoYear}
                onChange={(e) => {
                  handleChangeVideo('videoYear', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Video art edition</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={video.videoArtEdition}
                onChange={(e) => {
                  handleChangeVideo('videoArtEdition', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Video presented by</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={video.videoPresented}
                onChange={(e) => {
                  handleChangeVideo('videoPresented', e.target.value);
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <br />
      <Box style={{ border: '1px solid lightgray', borderRadius: '10px' }}>
        <Grid container spacing={2} style={{ padding: '2vw' }}>
          <Typography component="h5">Second Video content</Typography>
          <Grid
            lg={12}
            md={12}
            sm={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1vw',
              flexDirection: 'row'
            }}
          >
            <Grid lg={6} md={6} sm={12}>
              <ThemeProvider theme={theme}>
                <DropzoneArea
                  acceptedFiles={['video/*']}
                  dropzoneText={'Drag and drop the second preview video here'}
                  showPreviewsInDropzone={true}
                  onChange={(event) => {
                    if (event.length > 0) {
                      handleVideo2('previewVideo', event[0]);
                    }
                  }}
                  maxFileSize={12000000}
                  filesLimit={1}
                />
                <Typography variant="caption" style={{ color: '#fe309c' }}>
                  For better resolution insert videos with 1920x1080
                </Typography>
                <br />
              </ThemeProvider>
            </Grid>
            <Grid lg={6} md={6} sm={12}>
              {typeof video2.previewVideo === 'string' && (
                <video
                  style={{ width: '70%', height: '70%' }}
                  controls
                  src={treatURL(video2.previewVideo)}
                />
              )}
            </Grid>
          </Grid>
          <br />
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">
                Second Youtube video URL
              </InputLabel>
              <Input
                id="name-input"
                type="text"
                value={video2.youtubeURL}
                onChange={(e) => {
                  handleVideo2('youtubeURL', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">
                Second video artist name
              </InputLabel>
              <Input
                id="name-input"
                type="text"
                value={video2.videoartistName}
                onChange={(e) => {
                  handleVideo2('videoartistName', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Second video Title</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={video2.videoTitle}
                onChange={(e) => {
                  handleVideo2('videoTitle', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Second video year</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={video2.videoYear}
                onChange={(e) => {
                  handleVideo2('videoYear', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">
                Second video art edition
              </InputLabel>
              <Input
                id="name-input"
                type="text"
                value={video2.videoArtEdition}
                onChange={(e) => {
                  handleVideo2('videoArtEdition', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">
                Second video presented by
              </InputLabel>
              <Input
                id="name-input"
                type="text"
                value={video2.videoPresented}
                onChange={(e) => {
                  handleVideo2('videoPresented', e.target.value);
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <br />
      <Box style={{ border: '1px solid lightgray', borderRadius: '10px' }}>
        <Grid container spacing={2} style={{ padding: '2vw' }}>
          <Grid lg={12} md={12} sm={12}>
            <Typography component="h5">Latest card content</Typography>
          </Grid>{' '}
          <Grid
            lg={12}
            md={12}
            sm={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1vw',
              flexDirection: 'row'
            }}
          >
            <Grid lg={6} md={6} sm={12}>
              <ThemeProvider theme={theme}>
                <DropzoneArea
                  acceptedFiles={['image/*']}
                  dropzoneText={'Drag and drop the latest image here'}
                  showPreviewsInDropzone={true}
                  onChange={(event) => {
                    if (event.length > 0) {
                      handleChangeLatest('latest_image', event[0]);
                    }
                  }}
                  filesLimit={0}
                />
                <Typography variant="caption" style={{ color: '#fe309c' }}>
                  For better resolution insert images with 1392x1080
                </Typography>
                <br />
              </ThemeProvider>
            </Grid>
            <Grid lg={6} md={6} sm={12}>
              {typeof latest.latest_image === 'string' && (
                <img
                  style={{ height: '100px', width: 'auto' }}
                  src={treatURL(latest.latest_image)}
                />
              )}
            </Grid>
          </Grid>
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Latest title</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={latest.latest_title}
                onChange={(e) => {
                  handleChangeLatest('latest_title', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">
                Latest text
              </InputLabel>
              <Input
                id="name-input"
                type="text"
                value={latest.latest_hover_text}
                onChange={(e) => {
                  handleChangeLatest('latest_hover_text', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Latest URL</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={latest.latest_url}
                onChange={(e) => {
                  handleChangeLatest('latest_url', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
        </Grid>
      </Box>
      <br />
      <Box style={{ border: '1px solid lightgray', borderRadius: '10px' }}>
        <Grid container spacing={2} style={{ padding: '2vw' }}>
          <Typography component="h5">Featured artist content</Typography>
          <Grid
            lg={12}
            md={12}
            sm={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1vw',
              flexDirection: 'row'
            }}
          >
            <Grid lg={6} md={6} sm={12}>
              <ThemeProvider theme={theme}>
                <DropzoneArea
                  acceptedFiles={['image/*']}
                  dropzoneText={'Drag and drop the featured artist image here'}
                  showPreviewsInDropzone={true}
                  onChange={(event) => {
                    if (event.length > 0) {
                      handleFeatured('featured_artist_image', event[0]);
                    }
                  }}
                  filesLimit={0}
                />
                <Typography variant="caption" style={{ color: '#fe309c' }}>
                  For better resolution insert images with 2500x1250
                </Typography>
                <br />
              </ThemeProvider>
            </Grid>
            <Grid lg={6} md={6} sm={12}>
              {typeof featured.featured_artist_image === 'string' && (
                <img
                  style={{ height: '100px', width: 'auto' }}
                  src={treatURL(featured.featured_artist_image)}
                />
              )}
            </Grid>
          </Grid>
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">
                Featured artist title
              </InputLabel>
              <Input
                id="name-input"
                type="text"
                value={featured.featured_artist_title}
                onChange={(e) => {
                  handleFeatured('featured_artist_title', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Featured artist URL</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={featured.featured_artist_page_url}
                onChange={(e) => {
                  handleFeatured('featured_artist_page_url', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
        </Grid>
      </Box>
      <br />
      <Box style={{ border: '1px solid lightgray', borderRadius: '10px' }}>
        <Grid container spacing={2} style={{ padding: '2vw' }}>
          <Grid lg={12} md={12} sm={12}>
            <Typography component="h5">Social card content</Typography>
          </Grid>{' '}
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1vw',
              flexDirection: 'row'
            }}
            lg={12}
            md={12}
            sm={12}
          >
            <Grid lg={6} md={6} sm={12}>
              <ThemeProvider theme={theme}>
                <DropzoneArea
                  acceptedFiles={['image/*']}
                  dropzoneText={'Drag and drop the social image here'}
                  showPreviewsInDropzone={true}
                  onChange={(event) => {
                    if (event.length > 0) {
                      handleSocial('social_image', event[0]);
                    }
                  }}
                  filesLimit={0}
                />
                <Typography variant="caption" style={{ color: '#fe309c' }}>
                  For better resolution insert images with 2500x1250
                </Typography>
                <br />
              </ThemeProvider>
            </Grid>
            <Grid lg={6} md={6} sm={12}>
              {typeof social.social_image === 'string' && (
                <img
                  style={{ height: '100px', width: 'auto' }}
                  src={treatURL(social.social_image)}
                />
              )}
            </Grid>
          </Grid>
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Social title</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={social.social_title}
                onChange={(e) => {
                  handleSocial('social_title', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Social link</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={social.social_link}
                onChange={(e) => {
                  handleSocial('social_link', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
        </Grid>
      </Box>
      <br />
      <Box style={{ border: '1px solid lightgray', borderRadius: '10px' }}>
        <Grid container spacing={2} style={{ padding: '2vw' }}>
          <Grid lg={12} md={12} sm={12}>
            <Typography component="h5">Services card content</Typography>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Services image title</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={services.services_image_title}
                onChange={(e) => {
                  handleServices('services_image_title', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Services title</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={services.services_title}
                onChange={(e) => {
                  handleServices('services_title', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Services hover text</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={services.services_hover_text}
                onChange={(e) => {
                  handleServices('services_hover_text', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
        </Grid>
      </Box>
      <br />
      <Box style={{ border: '1px solid lightgray', borderRadius: '10px' }}>
        <Grid container spacing={2} style={{ padding: '2vw' }}>
          <Grid lg={12} md={12} sm={12}>
            <Typography component="h5">Gifting section content</Typography>
          </Grid>
          <br />
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">
                Gifting first message
              </InputLabel>
              <Input
                id="name-input"
                type="text"
                value={gifting.gifting_first_message}
                onChange={(e) => {
                  handleGifting('gifting_first_message', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">
                Gifting second message
              </InputLabel>
              <Input
                id="name-input"
                type="text"
                value={gifting.gifting_second_message}
                onChange={(e) => {
                  handleGifting('gifting_second_message', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Gifting URL</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={gifting.gifting_url}
                onChange={(e) => {
                  handleGifting('gifting_url', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
        </Grid>
      </Box>
      <br />{' '}
      <Box style={{ border: '1px solid lightgray', borderRadius: '10px' }}>
        <Grid container spacing={2} style={{ padding: '2vw' }}>
          <Grid lg={12} md={12} sm={12}>
            <Typography component="h5">Links content</Typography>
          </Grid>
          <br />
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Whatsapp Link</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={links.whatsapp}
                onChange={(e) => {
                  handleLinks('whatsapp', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Wechat Link</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={links.wechat}
                onChange={(e) => {
                  handleLinks('wechat', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Email</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={links.email}
                onChange={(e) => {
                  handleLinks('email', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Instagram Link</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={links.instagram}
                onChange={(e) => {
                  handleLinks('instagram', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
          <Grid lg={6} md={6} sm={12}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="name-input">Facebook Link</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={links.facebook}
                onChange={(e) => {
                  handleLinks('facebook', e.target.value);
                }}
              />
            </FormControl>
          </Grid>{' '}
        </Grid>
      </Box>
      <br />{' '}
    </div>
  );
}
